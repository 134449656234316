// @flow
import React from "react";

//$FlowFixMe
export const navigationContext = React.createContext([]);

class NavigationProvider extends React.Component<*, *> {
    state = {
        navigationStack: [],
    };

    pushNavigationEntry = (navEntry: *) => {
        const updatedStack = this.state.navigationStack.concat(navEntry);
        this.setState({
            navigationStack: updatedStack,
        });
    };

    render() {
        const { navigationStack } = this.state;

        return (
            <navigationContext.Provider
                value={{
                    navigationStack,
                    pushNavigationEntry: (navEntry: *) =>
                        this.pushNavigationEntry(navEntry),
                }}
            >
                {this.props.children}
            </navigationContext.Provider>
        );
    }
}

const Provider = ({ element }: *) => (
    <NavigationProvider>{element}</NavigationProvider>
);

export default Provider;
