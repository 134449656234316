/* eslint-disable no-console */
// @flow
import { PipProvider } from "@utils/PipPlayer/PipContext";
import React, { useState } from "react";

// eslint-disable-next-line react/display-name
export default ({ children }: *) => {
    const [videoData, setVideoData] = useState({
        id: null,
        startTime: 0,
        isPaused: false,
        autoplay: false,
    });

    return (
        <PipProvider value={{ videoData, setVideoData }}>
            {children}
        </PipProvider>
    );
};
