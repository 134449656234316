/**
 * Get a normalized path name
 */
export default () => {
    if (window.location.pathname.startsWith("/")) {
        return window.location.pathname;
    }

    return `/${window.location.pathname}`;
};
