const { contentfulTypenames } = require("../ContentfulConstants");

const COLLECTION_PAGESIZE = 18;
const COLLECTION_EXPERT_PAGESIZE_RELATED = 8;
const COLLECTION_ARTICLE_PAGESIZE_RELATED = 3;
const COLLECTION_ALL_EXPERTS = 18;
const COLLECTION_CAROUSEL = 4;
const COLLECTION_EXPERT_LIST = 4;
const DOSSIERTEASER_EXPERT_LIST = 3;
const SORTORDER_ASCENDING = "ASC";
const SORTORDER_DESCENDING = "DESC";
const FILTER_LATEST = "latest";
const FILTER_OLDEST = "oldest";
const FILTER_VIDEOS = "videos";
const PAGE_CONTEXTS = {
    DOSSIER: "dossier",
};
const OFFICE_URL_PAR = "office";
const PAGE_TYPES = {
    ARTICLE: "Page:Article",
    CATEGORY: "Page:Category",
    COLLECTION: "Page:Collection",
    CONTENT: "Page: Content",
    DOSSIER: "Page:Dossier",
    EDITION: "Page:Edition",
    HOME: "Page:Home",
    LANDING: "Page:Landing",
    NOTFOUND: "Page:404",
    PERSON: "Page:Person",
    PODCAST: "Page:Podcast",
    OFFICE: "Page:Office",
    SEARCH: "Page:Search",
    COMPANY: "Page:Company",
    EVENT: "Page:Event",
    PAGE_404: "Page:404",
};

const NODE_TYPES = {
    PARAGRAPH: "paragraph",
};

const CHANNEL_ACADEMY = "academy.degroofpetercam.com";
const MEETINGCHOICE_CONFCALL = "VideoConference";
const MEETINGCHOICE_HOME = "Home";
const MEETINGCHOICE_BDP = "BDP";
const MEETINGCHOICE_ARGENX = "ArgenX";
const FORMVERSION_SHORT = "Short";
const FORMVERSION_LONG = "Long";
const FORMTYPE_ARGENX = "ArgenX";

const VIDEO_PLAY = "VIDEO_PLAY";
const STORAGE_LEAD_SCORING = "STORAGE_LEAD_SCORING";
const STORAGE_DISCLAIMERS = "STORAGE_DISCLAIMERS";
const STORAGE_BREADCRUMB = {
    nl: "STORAGE_BREADCRUMB_NL",
    fr: "STORAGE_BREADCRUMB_FR",
    en: "STORAGE_BREADCRUMB_EN",
};
const STORAGE_LOCATION = "STORAGE_LOCATION";
const STORAGE_LANGUAGE = "STORAGE_LANGUAGE";
const LEAD_SCORING_DEFAULT_VALUES = {
    [PAGE_TYPES.ARTICLE]: 2,
    [PAGE_TYPES.CATEGORY]: 2,
    [PAGE_TYPES.COLLECTION]: 0,
    [PAGE_TYPES.CONTENT]: 0,
    [PAGE_TYPES.DOSSIER]: 0,
    [PAGE_TYPES.EDITION]: 0,
    [PAGE_TYPES.HOME]: 0,
    [PAGE_TYPES.LANDING]: 2,
    [PAGE_TYPES.NOTFOUND]: 0,
    [PAGE_TYPES.PERSON]: 0,
    [PAGE_TYPES.PODCAST]: 0,
    [PAGE_TYPES.OFFICE]: 0,
    [PAGE_TYPES.SEARCH]: 0,
    [PAGE_TYPES.COMPANY]: 0,
    [VIDEO_PLAY]: 5,
    [PAGE_TYPES.PAGE_404]: 0,
};

const LANGUAGE_COOKIE_NAME = "_dgp_user_language";
const LANGUAGE_COOKIE_LIFETIME = 90; // nr of days
const OFFER_COOKIE_NAME = "_dgp_user_offer";
const CONSENT = "_dgp_policy_status";

const MOBILE_PANEL_HEADER = "Menu";

const SHOW_WHEN_TYPES = {
    lands: "User lands on page",
    ten_seconds: "User spends 10 seconds on page",
    seventy_percent: "User scrolls 70% of page",
};

const CONTENTMODELS_WITH_ROOT_MARKETS = [
    contentfulTypenames.page,
    contentfulTypenames.homePage,
    contentfulTypenames.landingPage,
    contentfulTypenames.offer,
];

const SEARCHABLE_PAGETYPES = [
    contentfulTypenames.pageTypeEdition,
    contentfulTypenames.pageTypeDossier,
    contentfulTypenames.pageTypeArticle,
    contentfulTypenames.pageTypeOffice,
    contentfulTypenames.pageTypeCompany,
    contentfulTypenames.pageTypeEvent,
];

const SEGMENTS = {
    younggen: "Young Gen",
    genx: "Gen X",
    bb: "Babyboomers",
    fbo: "FBO",
    ip: "Independent Professionals",
    pw: "Professional Wealth",
};

const BACKGROUND_COLORS = {
    dark: "dark",
    light: "light",
    white: "white",
};

const VIDEO_SIZES = {
    small: "small",
    medium: "medium",
    large: "large",
    fullWidth: "full width",
};

const DISPLAY_SPLIT_ROW_SIZES = {
    even: 6,
    small: 4,
    large: 8,
};

const DISPLAY_ENTRY_PDFVIEWER = "PDFviewer";

const BOOL = "bool";
const YES = "Yes";
const NO = "No";
const BOOL_VALUES = [YES, NO];

const PHONECOUNTRIES = [
    {
        label: "+31 NL",
        value: "+31",
    },
    {
        label: "+32 BE",
        value: "+32",
    },
    {
        label: "+33 FR",
        value: "+33",
    },
    {
        label: "+352 LU",
        value: "+352",
    },
    {
        label: "+93 AF",
        value: "+93",
    },
    {
        label: "+355 AL",
        value: "+355",
    },
    {
        label: "+213 DZ",
        value: "+213",
    },
    {
        label: "+1-684 AS",
        value: "+1-684",
    },
    {
        label: "+376 AD",
        value: "+376",
    },
    {
        label: "+244 AO",
        value: "+244",
    },
    {
        label: "+1-264 AI",
        value: "+1-264",
    },
    {
        label: "+672 AQ",
        value: "+672",
    },
    {
        label: "+1-268 AG",
        value: "+1-268",
    },
    {
        label: "+54 AR",
        value: "+54",
    },
    {
        label: "+374 AM",
        value: "+374",
    },
    {
        label: "+297 AW",
        value: "+297",
    },
    {
        label: "+61 AU",
        value: "+61",
    },
    {
        label: "+43 AT",
        value: "+43",
    },
    {
        label: "+994 AZ",
        value: "+994",
    },
    {
        label: "+1-242 BS",
        value: "+1-242",
    },
    {
        label: "+973 BH",
        value: "+973",
    },
    {
        label: "+880 BD",
        value: "+880",
    },
    {
        label: "+1-246 BB",
        value: "+1-246",
    },
    {
        label: "+375 BY",
        value: "+375",
    },
    {
        label: "+501 BZ",
        value: "+501",
    },
    {
        label: "+229 BJ",
        value: "+229",
    },
    {
        label: "+1-441 BM",
        value: "+1-441",
    },
    {
        label: "+975 BT",
        value: "+975",
    },
    {
        label: "+591 BO",
        value: "+591",
    },
    {
        label: "+387 BA",
        value: "+387",
    },
    {
        label: "+267 BW",
        value: "+267",
    },
    {
        label: "+55 BR",
        value: "+55",
    },
    {
        label: "+246 IO",
        value: "+246",
    },
    {
        label: "+1-284 VG",
        value: "+1-284",
    },
    {
        label: "+673 BN",
        value: "+673",
    },
    {
        label: "+359 BG",
        value: "+359",
    },
    {
        label: "+226 BF",
        value: "+226",
    },
    {
        label: "+257 BI",
        value: "+257",
    },
    {
        label: "+855 KH",
        value: "+855",
    },
    {
        label: "+237 CM",
        value: "+237",
    },
    {
        label: "+1 CA",
        value: "+1",
    },
    {
        label: "+238 CV",
        value: "+238",
    },
    {
        label: "+1-345 KY",
        value: "+1-345",
    },
    {
        label: "+236 CF",
        value: "+236",
    },
    {
        label: "+235 TD",
        value: "+235",
    },
    {
        label: "+56 CL",
        value: "+56",
    },
    {
        label: "+86 CN",
        value: "+86",
    },
    {
        label: "+61 CX",
        value: "+61",
    },
    {
        label: "+61 CC",
        value: "+61",
    },
    {
        label: "+57 CO",
        value: "+57",
    },
    {
        label: "+269 KM",
        value: "+269",
    },
    {
        label: "+682 CK",
        value: "+682",
    },
    {
        label: "+506 CR",
        value: "+506",
    },
    {
        label: "+385 HR",
        value: "+385",
    },
    {
        label: "+53 CU",
        value: "+53",
    },
    {
        label: "+599 CW",
        value: "+599",
    },
    {
        label: "+357 CY",
        value: "+357",
    },
    {
        label: "+420 CZ",
        value: "+420",
    },
    {
        label: "+243 CD",
        value: "+243",
    },
    {
        label: "+45 DK",
        value: "+45",
    },
    {
        label: "+253 DJ",
        value: "+253",
    },
    {
        label: "+1-767 DM",
        value: "+1-767",
    },
    {
        label: "+1-809 DO",
        value: "+1-809",
    },
    {
        label: "+670 TL",
        value: "+670",
    },
    {
        label: "+593 EC",
        value: "+593",
    },
    {
        label: "+20 EG",
        value: "+20",
    },
    {
        label: "+503 SV",
        value: "+503",
    },
    {
        label: "+240 GQ",
        value: "+240",
    },
    {
        label: "+291 ER",
        value: "+291",
    },
    {
        label: "+372 EE",
        value: "+372",
    },
    {
        label: "+251 ET",
        value: "+251",
    },
    {
        label: "+500 FK",
        value: "+500",
    },
    {
        label: "+298 FO",
        value: "+298",
    },
    {
        label: "+679 FJ",
        value: "+679",
    },
    {
        label: "+358 FI",
        value: "+358",
    },
    {
        label: "+689 PF",
        value: "+689",
    },
    {
        label: "+241 GA",
        value: "+241",
    },
    {
        label: "+220 GM",
        value: "+220",
    },
    {
        label: "+995 GE",
        value: "+995",
    },
    {
        label: "+49 DE",
        value: "+49",
    },
    {
        label: "+233 GH",
        value: "+233",
    },
    {
        label: "+350 GI",
        value: "+350",
    },
    {
        label: "+30 GR",
        value: "+30",
    },
    {
        label: "+299 GL",
        value: "+299",
    },
    {
        label: "+1-473 GD",
        value: "+1-473",
    },
    {
        label: "+1-671 GU",
        value: "+1-671",
    },
    {
        label: "+502 GT",
        value: "+502",
    },
    {
        label: "+44-1481 GG",
        value: "+44-1481",
    },
    {
        label: "+224 GN",
        value: "+224",
    },
    {
        label: "+245 GW",
        value: "+245",
    },
    {
        label: "+592 GY",
        value: "+592",
    },
    {
        label: "+509 HT",
        value: "+509",
    },
    {
        label: "+504 HN",
        value: "+504",
    },
    {
        label: "+852 HK",
        value: "+852",
    },
    {
        label: "+36 HU",
        value: "+36",
    },
    {
        label: "+354 IS",
        value: "+354",
    },
    {
        label: "+91 IN",
        value: "+91",
    },
    {
        label: "+62 ID",
        value: "+62",
    },
    {
        label: "+98 IR",
        value: "+98",
    },
    {
        label: "+964 IQ",
        value: "+964",
    },
    {
        label: "+353 IE",
        value: "+353",
    },
    {
        label: "+44-1624 IM",
        value: "+44-1624",
    },
    {
        label: "+972 IL",
        value: "+972",
    },
    {
        label: "+39 IT",
        value: "+39",
    },
    {
        label: "+225 CI",
        value: "+225",
    },
    {
        label: "+1-876 JM",
        value: "+1-876",
    },
    {
        label: "+81 JP",
        value: "+81",
    },
    {
        label: "+44-1534 JE",
        value: "+44-1534",
    },
    {
        label: "+962 JO",
        value: "+962",
    },
    {
        label: "+7 KZ",
        value: "+7",
    },
    {
        label: "+254 KE",
        value: "+254",
    },
    {
        label: "+686 KI",
        value: "+686",
    },
    {
        label: "+383 XK",
        value: "+383",
    },
    {
        label: "+965 KW",
        value: "+965",
    },
    {
        label: "+996 KG",
        value: "+996",
    },
    {
        label: "+856 LA",
        value: "+856",
    },
    {
        label: "+371 LV",
        value: "+371",
    },
    {
        label: "+961 LB",
        value: "+961",
    },
    {
        label: "+266 LS",
        value: "+266",
    },
    {
        label: "+231 LR",
        value: "+231",
    },
    {
        label: "+218 LY",
        value: "+218",
    },
    {
        label: "+423 LI",
        value: "+423",
    },
    {
        label: "+370 LT",
        value: "+370",
    },
    {
        label: "+853 MO",
        value: "+853",
    },
    {
        label: "+389 MK",
        value: "+389",
    },
    {
        label: "+261 MG",
        value: "+261",
    },
    {
        label: "+265 MW",
        value: "+265",
    },
    {
        label: "+60 MY",
        value: "+60",
    },
    {
        label: "+960 MV",
        value: "+960",
    },
    {
        label: "+223 ML",
        value: "+223",
    },
    {
        label: "+356 MT",
        value: "+356",
    },
    {
        label: "+692 MH",
        value: "+692",
    },
    {
        label: "+222 MR",
        value: "+222",
    },
    {
        label: "+230 MU",
        value: "+230",
    },
    {
        label: "+262 YT",
        value: "+262",
    },
    {
        label: "+52 MX",
        value: "+52",
    },
    {
        label: "+691 FM",
        value: "+691",
    },
    {
        label: "+373 MD",
        value: "+373",
    },
    {
        label: "+377 MC",
        value: "+377",
    },
    {
        label: "+976 MN",
        value: "+976",
    },
    {
        label: "+382 ME",
        value: "+382",
    },
    {
        label: "+1-664 MS",
        value: "+1-664",
    },
    {
        label: "+212 MA",
        value: "+212",
    },
    {
        label: "+258 MZ",
        value: "+258",
    },
    {
        label: "+95 MM",
        value: "+95",
    },
    {
        label: "+264 NA",
        value: "+264",
    },
    {
        label: "+674 NR",
        value: "+674",
    },
    {
        label: "+977 NP",
        value: "+977",
    },
    {
        label: "+599 AN",
        value: "+599",
    },
    {
        label: "+687 NC",
        value: "+687",
    },
    {
        label: "+64 NZ",
        value: "+64",
    },
    {
        label: "+505 NI",
        value: "+505",
    },
    {
        label: "+227 NE",
        value: "+227",
    },
    {
        label: "+234 NG",
        value: "+234",
    },
    {
        label: "+683 NU",
        value: "+683",
    },
    {
        label: "+850 KP",
        value: "+850",
    },
    {
        label: "+1-670 MP",
        value: "+1-670",
    },
    {
        label: "+47 NO",
        value: "+47",
    },
    {
        label: "+968 OM",
        value: "+968",
    },
    {
        label: "+92 PK",
        value: "+92",
    },
    {
        label: "+680 PW",
        value: "+680",
    },
    {
        label: "+970 PS",
        value: "+970",
    },
    {
        label: "+507 PA",
        value: "+507",
    },
    {
        label: "+675 PG",
        value: "+675",
    },
    {
        label: "+595 PY",
        value: "+595",
    },
    {
        label: "+51 PE",
        value: "+51",
    },
    {
        label: "+63 PH",
        value: "+63",
    },
    {
        label: "+64 PN",
        value: "+64",
    },
    {
        label: "+48 PL",
        value: "+48",
    },
    {
        label: "+351 PT",
        value: "+351",
    },
    {
        label: "+1-787 PR",
        value: "+1-787",
    },
    {
        label: "+974 QA",
        value: "+974",
    },
    {
        label: "+242 CG",
        value: "+242",
    },
    {
        label: "+262 RE",
        value: "+262",
    },
    {
        label: "+40 RO",
        value: "+40",
    },
    {
        label: "+7 RU",
        value: "+7",
    },
    {
        label: "+250 RW",
        value: "+250",
    },
    {
        label: "+590 BL",
        value: "+590",
    },
    {
        label: "+290 SH",
        value: "+290",
    },
    {
        label: "+1-869 KN",
        value: "+1-869",
    },
    {
        label: "+1-758 LC",
        value: "+1-758",
    },
    {
        label: "+590 MF",
        value: "+590",
    },
    {
        label: "+508 PM",
        value: "+508",
    },
    {
        label: "+1-784 VC",
        value: "+1-784",
    },
    {
        label: "+685 WS",
        value: "+685",
    },
    {
        label: "+378 SM",
        value: "+378",
    },
    {
        label: "+239 ST",
        value: "+239",
    },
    {
        label: "+966 SA",
        value: "+966",
    },
    {
        label: "+221 SN",
        value: "+221",
    },
    {
        label: "+381 RS",
        value: "+381",
    },
    {
        label: "+248 SC",
        value: "+248",
    },
    {
        label: "+232 SL",
        value: "+232",
    },
    {
        label: "+65 SG",
        value: "+65",
    },
    {
        label: "+1-721 SX",
        value: "+1-721",
    },
    {
        label: "+421 SK",
        value: "+421",
    },
    {
        label: "+386 SI",
        value: "+386",
    },
    {
        label: "+677 SB",
        value: "+677",
    },
    {
        label: "+252 SO",
        value: "+252",
    },
    {
        label: "+27 ZA",
        value: "+27",
    },
    {
        label: "+82 KR",
        value: "+82",
    },
    {
        label: "+211 SS",
        value: "+211",
    },
    {
        label: "+34 ES",
        value: "+34",
    },
    {
        label: "+94 LK",
        value: "+94",
    },
    {
        label: "+249 SD",
        value: "+249",
    },
    {
        label: "+597 SR",
        value: "+597",
    },
    {
        label: "+47 SJ",
        value: "+47",
    },
    {
        label: "+268 SZ",
        value: "+268",
    },
    {
        label: "+46 SE",
        value: "+46",
    },
    {
        label: "+41 CH",
        value: "+41",
    },
    {
        label: "+963 SY",
        value: "+963",
    },
    {
        label: "+886 TW",
        value: "+886",
    },
    {
        label: "+992 TJ",
        value: "+992",
    },
    {
        label: "+255 TZ",
        value: "+255",
    },
    {
        label: "+66 TH",
        value: "+66",
    },
    {
        label: "+228 TG",
        value: "+228",
    },
    {
        label: "+690 TK",
        value: "+690",
    },
    {
        label: "+676 TO",
        value: "+676",
    },
    {
        label: "+1-868 TT",
        value: "+1-868",
    },
    {
        label: "+216 TN",
        value: "+216",
    },
    {
        label: "+90 TR",
        value: "+90",
    },
    {
        label: "+993 TM",
        value: "+993",
    },
    {
        label: "+1-649 TC",
        value: "+1-649",
    },
    {
        label: "+688 TV",
        value: "+688",
    },
    {
        label: "+1-340 VI",
        value: "+1-340",
    },
    {
        label: "+256 UG",
        value: "+256",
    },
    {
        label: "+380 UA",
        value: "+380",
    },
    {
        label: "+971 AE",
        value: "+971",
    },
    {
        label: "+44 GB",
        value: "+44",
    },
    {
        label: "+1 US",
        value: "+1",
    },
    {
        label: "+598 UY",
        value: "+598",
    },
    {
        label: "+998 UZ",
        value: "+998",
    },
    {
        label: "+678 VU",
        value: "+678",
    },
    {
        label: "+379 VA",
        value: "+379",
    },
    {
        label: "+58 VE",
        value: "+58",
    },
    {
        label: "+84 VN",
        value: "+84",
    },
    {
        label: "+681 WF",
        value: "+681",
    },
    {
        label: "+212 EH",
        value: "+212",
    },
    {
        label: "+967 YE",
        value: "+967",
    },
    {
        label: "+260 ZM",
        value: "+260",
    },
    {
        label: "+263 ZW",
        value: "+263",
    },
];

const SPLIT_OPTIONS = {
    EVEN_SPLIT: "Evenly split (50-50%)",
    UNEVEN_SPLIT_RIGHT: "Unevenly split right (75-25%)",
    UNEVEN_SPLIT_LEFT: "Unevenly split left (25-75%)",
};

const DOCUMENT_SORT_OPTIONS = {
    OLDEST_FIRST: "Oldest first",
    NEWEST_FIRST: "Newest first",
};

const BUILDCHANNEL_SOURCE_HOMEPAGE = "shouldSourceHomePage";
const BUILDCHANNEL_GENERATE_SEARCHPAGE = "shouldGenerateSearchPage";
const BUILDCHANNEL_GENERATE_SEARCHINDEX = "shouldGenerateSearchIndex";
const BUILDCHANNEL_DISPLAY_CATEGORYLINKS = "shouldDisplayCategoryLinks";
const BUILDCHANNEL_DISPLAY_DOSSIERS = "shouldDisplayDossiers";
const BUILDCHANNEL_MARKET_SWITCH_NEW = "shouldFollowCTAOnMarketSwitch";

const CONTENT_GROUP_VALUES = {
    actionBanners: "action banners",
    actionCards: "action cards",
    articles: "articles",
    commissionedResearch: "commissioned research",
    company: "company",
    documents: "documents",
    dossiers: "dossiers",
    editionExperts: "editionExperts",
    events: "events",
    faqs: "faqs",
    iconCards: "icon cards",
    expandableCards: "expandable cards",
    mots: "mots",
    multimedia: "multimedia",
    offices: "offices",
    persons: "persons",
    testimonials: "testimonials",
    transactions: "transactions",
    contentSelector: "ContentfulAppropriateContentSelector",
};

const CONTENT_TYPE_COMMISSIONEDRESEARCH = "commissionedResearch";

const CARD_DESIGN_VALUES = {
    mot: "MoT",
    actionCard: "Action Card",
    iconCard: "Icon Card",
};

const DESIGN_VALUES = {
    vertical: "Vertical",
    horizontal: "Horizontal",
    displayVertical: "row",
    displayHorizontal: "column",
};

const COLUMN_VALUES = {
    default: "default",
    fullWidth: "fullWidth",
    twoItems: "twoItems",
    threeItems: "threeItems",
    fourItems: "fourItems",
    fourItemsXxl: "fourItemsXxl",
};

const BREAKPOINTS = {
    sm: 480,
    md: 768,
    lg: 1024,
    xl: 1366,
    xxl: 1920,
    xxxl: 2560,
};

const LOGIN_URL = {
    be: "https://my.degroofpetercam.be",
    fr: "https://www.mydegroof.fr/",
    lu: "https://my.degroofpetercam.lu",
};

const BLOG_DEFAULT_URL_PREFIX = "blog";

const SEARCH_ARTICLE_CATEGORY = "blog";

const SEARCH_FAQ_CATEGORY = "faq";

const SEARCH_DOCUMENT_CATEGORY = "document";

const COLLECTION_DEFAULT_SIZE = {
    [contentfulTypenames.articles]: 12,
    default: 16,
};

const SUPPORTED_MARKETS_AND_LANGUAGES = {
    markets: ["be", "fr", "lu"],
    languages: ["nl", "fr", "en"],
};

const SUPPORTED_LANG_PER_MARKET = {
    be: ["nl", "fr", "en"],
    fr: ["fr"],
    lu: ["nl", "fr", "en"],
};

const SEGMENT_TAG_PREFIX = "Segment: ";
const SEGMENT_PREFIX = "segment";

const COLUMN_A = "column A";
const COLUMN_B = "column B";
const ASSETS_URL = "https://assets.degroofpetercam.com";
const ASSETS_CF_URL = "//assets.ctfassets.net";
const ENTRY_TYPES = {
    array: "array",
};

const NODES = {
    tagsNode: "tags___NODE",
    categoriesNode: "categories___NODE",
    responsibilitiesNode: "responsibilities___NODE",
    responsibilityNode: "responsibility___NODE",
    companyNode: "company___NODE",
    versionsNode: "versions___NODE",
    defaultAnswerNode: "defaultAnswer___NODE",
    contextualAnswerNODE: "contextualAnswer___NODE",
    officeMarketsNode: "officeMarkets___NODE",
    marketsNode: "markets___NODE",
    attendeesNode: "companiesAttending___NODE",
    workfieldNode: "workfield___NODE",
    personNode: "person___NODE",
    services: "services___NODE",
    applyFilterNode: "applyFilter___NODE",
    filterNode: "filter___NODE",
    companiesNode: "companies___NODE",
    officeNode: "office___NODE",
    articleNode: "article___NODE",
    contentANode: "contentA___NODE",
    contentBNode: "contentB___NODE",
    name: "name",
    dropdownChoicesNODE: "dropdownChoices___NODE",
};

const LUNR_FIELDS = {
    title: "title",
    id: "idSearchable",
    pageType: "pageType",
    copy: "copy",
    slug: "slug",
    tags: "tags",
    cardDesigns: "cardDesignsSearchable",
    categories: "categoriesSearchable",
    roles: "roleSearchable",
    analysts: "analystsSearchable",
    sectors: "sectorsSearchable",
    services: "servicesSearchable",
    countries: "countriesSearchable",
    officeCountries: "officeCountriesSearchable",
    companies: "companiesSearchable",
    closingDates: "closingDates",
    publicSector: "publicSector",
    financialInvestors: "financialInvestors",
    familyBusinessOwners: "familyBusinessOwners",
    experts: "expertsSearchable",
    editions: "editionsSearchable",
    dossiers: "dossiersSearchable",
    search: "search",
    officeCities: "officeCitiesSearchable",
    offices: "officesSearchable",
    workfields: "workfieldsSearchable",
    questions: "questionsSearchable",
    noSearch: "noSearch",
    highlight: "highlight",
};

const QUERY_PARAMETERS = "QUERY_PARAMETERS";
const CONTENT_SELECTOR_LAYOUT = {
    singleCampaign: "1 Campaign",
    doubleArticle: "2 Blog articles",
    mixed: "1 Campaign + 1 blog article",
};

const HEADER_HEIGHT = 80;
const TWITTER_HANDLE = "degroofpetercam";

const ADDRESS = {
    FULL_ADDRESS: "fullAddress",
    STREET: "street",
    NR: "number",
    ZIP: "zipcode",
    CITY: "city",
    COUNTRY: "country",
    BOX: "box",
    ISOCODE: "isoCode",
};

const FORMALIZER = {
    ADDRESS: "address",
    BLACKLISTED_DOMAINS: "blacklisted_domains",
    CF: {
        TEXTBLOCK: "textBlock",
        IMAGE: "imageBlock",
        VIDEO: "videoBlock",
        CARD: "displayContentCard",
    },
    CONTENTFUL_ENTRY: "ContentfulEntry",
    CONTROLS: {
        ADDRESS: "AddressControl",
        CHECKBOX_GROUP: "CheckboxGroupControl",
        CHECKBOX: "CheckboxControl",
        CUSTOM_ENUM: "ContentfulEntryEnumControl",
        DROPDOWN: "DropdownControl",
        EMAIL: "EmailControl",
        HIDDEN: "HiddenControl",
        INT: "IntegerControl",
        NUMBER: "NumberControl",
        OFFICE: "OfficeControl",
        PHONE: "TelephoneControl",
        RADIO: "RadioGroupControl",
        TEXT: "TextControl",
        TEXTAREA: "TextMultilineControl",
    },
    INVALID_PHONE: "invalid_phone",
    REQUIRED_ADDRESS: "address_required",
    REQUIRED_STREET: "street_required",
    REQUIRED_NUMBER: "number_required",
    REQUIRED_COUNTRY: "country_required",
    REQUIRED_CITY: "city_required",
    REQUIRED_ZIPCODE: "zipcode_required",
    REQUIRED_CHECKBOX: "required_checkbox",
    REQUIRED_OBJECT: "required_object",
    RULE_ENABLED: "ENABLED",
    RULE_SHOW: "SHOW",
    RULE_HIDE: "HIDE",
    RULE_DISABLED: "DISABLED",
    SORT_OPTIONS: {
        NONE: "definition order",
        ASC: "ascending",
        DESC: "descending",
    },
};

const VALIDATION_TYPE_MAPPER = {
    min: "minimum",
    max: "maximum",
    matches: "pattern",
    [FORMALIZER.REQUIRED_CHECKBOX]: "required",
};

const CUSTOM_ERROR_TYPES = [
    "multipleOf",
    FORMALIZER.REQUIRED_CHECKBOX,
    FORMALIZER.REQUIRED_OBJECT,
    FORMALIZER.BLACKLISTED_DOMAINS,
    FORMALIZER.INVALID_PHONE,
    FORMALIZER.REQUIRED_ADDRESS,
    FORMALIZER.REQUIRED_COUNTRY,
    FORMALIZER.REQUIRED_STREET,
    FORMALIZER.REQUIRED_NUMBER,
    FORMALIZER.REQUIRED_ADDRESS,
    FORMALIZER.REQUIRED_CITY,
    FORMALIZER.REQUIRED_ZIPCODE,
];
const CUSTOM_ERROR_TRANSLATIONS = {
    fr: {
        multipleOf: "{{path}} moet een veelvoud zijn van {{multiple}}",
        [FORMALIZER.REQUIRED_CHECKBOX]: "{{path}} est un champ obligatoire",
        [FORMALIZER.REQUIRED_OBJECT]: "{{path}} est un champ obligatoire",
        [FORMALIZER.BLACKLISTED_DOMAINS]:
            "Le domaine de l'email n'est pas autorisé",
        [FORMALIZER.INVALID_PHONE]: "Numéro de téléphone invalide",
    },
    nl: {
        multipleOf: "{{path}} moet een veelvoud zijn van {{multiple}}",
        [FORMALIZER.REQUIRED_CHECKBOX]: "{{path}} is een verplicht veld",
        [FORMALIZER.REQUIRED_OBJECT]: "{{path}} is een verplicht veld",
        [FORMALIZER.BLACKLISTED_DOMAINS]: "E-maildomein is niet toegestaan",
        [FORMALIZER.INVALID_PHONE]: "Ongeldig telefoonnummer",
    },
};

const CAREER_FILTER_VALUES = {
    category: "Categories",
    country: "Countries",
    location: "Locations",
    categoryType: "Category",
    countryType: "Country",
    locationType: "Location",
};

module.exports.ADDRESS = ADDRESS;
module.exports.FORMALIZER = FORMALIZER;
module.exports.VIDEO_SIZES = VIDEO_SIZES;
module.exports.CUSTOM_ERROR_TYPES = CUSTOM_ERROR_TYPES;
module.exports.VALIDATION_TYPE_MAPPER = VALIDATION_TYPE_MAPPER;
module.exports.CUSTOM_ERROR_TRANSLATIONS = CUSTOM_ERROR_TRANSLATIONS;
module.exports.HEADER_HEIGHT = HEADER_HEIGHT;
module.exports.SEGMENT_PREFIX = SEGMENT_PREFIX;
module.exports.ENTRY_TYPES = ENTRY_TYPES;
module.exports.NODES = NODES;
module.exports.BOOL = BOOL;
module.exports.YES = YES;
module.exports.NO = NO;
module.exports.BOOL_VALUES = BOOL_VALUES;
module.exports.BLOG_DEFAULT_URL_PREFIX = BLOG_DEFAULT_URL_PREFIX;
module.exports.COLLECTION_PAGESIZE = COLLECTION_PAGESIZE;
module.exports.COLLECTION_EXPERT_PAGESIZE_RELATED = COLLECTION_EXPERT_PAGESIZE_RELATED;
module.exports.COLLECTION_ARTICLE_PAGESIZE_RELATED = COLLECTION_ARTICLE_PAGESIZE_RELATED;
module.exports.COLLECTION_ALL_EXPERTS = COLLECTION_ALL_EXPERTS;
module.exports.COLLECTION_CAROUSEL = COLLECTION_CAROUSEL;
module.exports.COLLECTION_EXPERT_LIST = COLLECTION_EXPERT_LIST;
module.exports.SORTORDER_ASCENDING = SORTORDER_ASCENDING;
module.exports.SORTORDER_DESCENDING = SORTORDER_DESCENDING;
module.exports.FILTER_LATEST = FILTER_LATEST;
module.exports.FILTER_OLDEST = FILTER_OLDEST;
module.exports.FILTER_VIDEOS = FILTER_VIDEOS;
module.exports.PAGE_CONTEXTS = PAGE_CONTEXTS;
module.exports.PAGE_TYPES = PAGE_TYPES;
module.exports.NODE_TYPES = NODE_TYPES;
module.exports.SHOW_WHEN_TYPES = SHOW_WHEN_TYPES;
module.exports.CONSENT = CONSENT;
module.exports.BACKGROUND_COLORS = BACKGROUND_COLORS;

module.exports.PHONECOUNTRIES = PHONECOUNTRIES;
module.exports.DOSSIERTEASER_EXPERT_LIST = DOSSIERTEASER_EXPERT_LIST;
module.exports.LANGUAGE_COOKIE_NAME = LANGUAGE_COOKIE_NAME;
module.exports.OFFER_COOKIE_NAME = OFFER_COOKIE_NAME;
module.exports.LANGUAGE_COOKIE_LIFETIME = LANGUAGE_COOKIE_LIFETIME;
module.exports.CONTENTMODELS_WITH_ROOT_MARKETS = CONTENTMODELS_WITH_ROOT_MARKETS;
module.exports.SEGMENTS = SEGMENTS;
module.exports.SPLIT_OPTIONS = SPLIT_OPTIONS;
module.exports.STORAGE_LEAD_SCORING = STORAGE_LEAD_SCORING;
module.exports.STORAGE_DISCLAIMERS = STORAGE_DISCLAIMERS;
module.exports.LEAD_SCORING_DEFAULT_VALUES = LEAD_SCORING_DEFAULT_VALUES;
module.exports.VIDEO_PLAY = VIDEO_PLAY;

module.exports.BUILDCHANNEL_SOURCE_HOMEPAGE = BUILDCHANNEL_SOURCE_HOMEPAGE;
module.exports.BUILDCHANNEL_GENERATE_SEARCHPAGE = BUILDCHANNEL_GENERATE_SEARCHPAGE;
module.exports.BUILDCHANNEL_GENERATE_SEARCHINDEX = BUILDCHANNEL_GENERATE_SEARCHINDEX;
module.exports.BUILDCHANNEL_DISPLAY_CATEGORYLINKS = BUILDCHANNEL_DISPLAY_CATEGORYLINKS;
module.exports.BUILDCHANNEL_DISPLAY_DOSSIERS = BUILDCHANNEL_DISPLAY_DOSSIERS;
module.exports.BUILDCHANNEL_MARKET_SWITCH_NEW = BUILDCHANNEL_MARKET_SWITCH_NEW;
module.exports.CHANNEL_ACADEMY = CHANNEL_ACADEMY;

module.exports.MOBILE_PANEL_HEADER = MOBILE_PANEL_HEADER;

module.exports.CONTENT_GROUP_VALUES = CONTENT_GROUP_VALUES;
module.exports.CARD_DESIGN_VALUES = CARD_DESIGN_VALUES;
module.exports.DESIGN_VALUES = DESIGN_VALUES;
module.exports.COLUMN_VALUES = COLUMN_VALUES;
module.exports.DISPLAY_SPLIT_ROW_SIZES = DISPLAY_SPLIT_ROW_SIZES;
module.exports.SEARCHABLE_PAGETYPES = SEARCHABLE_PAGETYPES;
module.exports.BREAKPOINTS = BREAKPOINTS;
module.exports.LOGIN_URL = LOGIN_URL;
module.exports.COLLECTION_DEFAULT_SIZE = COLLECTION_DEFAULT_SIZE;
module.exports.STORAGE_BREADCRUMB = STORAGE_BREADCRUMB;
module.exports.SUPPORTED_MARKETS_AND_LANGUAGES = SUPPORTED_MARKETS_AND_LANGUAGES;
module.exports.SUPPORTED_LANG_PER_MARKET = SUPPORTED_LANG_PER_MARKET;
module.exports.STORAGE_LOCATION = STORAGE_LOCATION;
module.exports.STORAGE_LANGUAGE = STORAGE_LANGUAGE;

module.exports.MEETINGCHOICE_CONFCALL = MEETINGCHOICE_CONFCALL;
module.exports.MEETINGCHOICE_ARGENX = MEETINGCHOICE_ARGENX;
module.exports.MEETINGCHOICE_BDP = MEETINGCHOICE_BDP;
module.exports.MEETINGCHOICE_HOME = MEETINGCHOICE_HOME;
module.exports.FORMVERSION_SHORT = FORMVERSION_SHORT;
module.exports.FORMVERSION_LONG = FORMVERSION_LONG;
module.exports.FORMTYPE_ARGENX = FORMTYPE_ARGENX;
module.exports.SEGMENT_TAG_PREFIX = SEGMENT_TAG_PREFIX;
module.exports.DISPLAY_ENTRY_PDFVIEWER = DISPLAY_ENTRY_PDFVIEWER;
module.exports.COLUMN_A = COLUMN_A;
module.exports.COLUMN_B = COLUMN_B;
module.exports.ASSETS_URL = ASSETS_URL;
module.exports.DOCUMENT_SORT_OPTIONS = DOCUMENT_SORT_OPTIONS;

module.exports.ASSETS_CF_URL = ASSETS_CF_URL;
module.exports.CONTENT_TYPE_COMMISSIONEDRESEARCH = CONTENT_TYPE_COMMISSIONEDRESEARCH;
module.exports.OFFICE_URL_PAR = OFFICE_URL_PAR;
module.exports.LUNR_FIELDS = LUNR_FIELDS;
module.exports.SEARCH_ARTICLE_CATEGORY = SEARCH_ARTICLE_CATEGORY;
module.exports.SEARCH_FAQ_CATEGORY = SEARCH_FAQ_CATEGORY;
module.exports.SEARCH_DOCUMENT_CATEGORY = SEARCH_DOCUMENT_CATEGORY;
module.exports.QUERY_PARAMETERS = QUERY_PARAMETERS;
module.exports.CONTENT_SELECTOR_LAYOUT = CONTENT_SELECTOR_LAYOUT;
module.exports.TWITTER_HANDLE = TWITTER_HANDLE;

module.exports.CAREER_FILTER_VALUES = CAREER_FILTER_VALUES;
