//@flow

export const loadLocalStorage = (id: *) => {
    // Escape SSR without error
    if (typeof window === "undefined") {
        return null;
    }

    try {
        const serializedState = localStorage.getItem(id);
        if (serializedState) {
            const state = JSON.parse(serializedState);
            if (new Date().getTime() > state.expiry) {
                localStorage.removeItem(id);
                return null;
            }
            return state.value;
        }
        return null;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
    }
};

export const saveLocalStorage = (id: *, state: *) => {
    // Escape SSR without error
    if (typeof window === "undefined") {
        return null;
    }

    try {
        const DAY = 100 * 60 * 60 * 24;
        const stateWithDate = {
            value: state,
            expiry: new Date().getTime() + DAY,
        };
        const serializedState = JSON.stringify(stateWithDate);
        serializedState && localStorage.setItem(id, serializedState);
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
    }
};

export const clearLocalStorage = (id: *) => {
    // Escape SSR without error
    if (typeof window === "undefined") {
        return null;
    }

    try {
        localStorage.removeItem(id);
        return null;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
    }
};

export const saveLocalStorageObject = (id: *, queryString: *) => {
    // Escape SSR without error
    if (typeof window === "undefined") {
        return null;
    }

    try {
        const storedQueries = loadLocalStorageObject(id);
        if (storedQueries) {
            const queries = JSON.parse(storedQueries);
            const index = queries.findIndex(
                object => object.value === queryString,
            );

            const WEEK = 100 * 60 * 60 * 24 * 7;
            const stateWithDate = {
                value: queryString,
                expiry: new Date().getTime() + WEEK,
            };

            if (index >= 0) {
                queries[index] = stateWithDate;
            } else {
                queries.push(stateWithDate);
            }
            localStorage.setItem(id, JSON.stringify(queries));
        }
        if (!storedQueries && queryString) {
            const queries = [];

            const WEEK = 100 * 60 * 60 * 24 * 7;
            const stateWithDate = {
                value: queryString,
                expiry: new Date().getTime() + WEEK,
            };

            queries.push(stateWithDate);

            localStorage.setItem(id, JSON.stringify(queries));
        }
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
    }
};

export const loadLocalStorageObject = (id: *) => {
    // Escape SSR without error
    if (typeof window === "undefined") {
        return null;
    }

    try {
        const serializedQueries = localStorage.getItem(id);
        const currentTime = new Date().getTime();

        if (serializedQueries) {
            const queryObjects = JSON.parse(serializedQueries);
            const validObjects = queryObjects.filter(
                queryObj => currentTime <= queryObj.expiry,
            );

            const updatedQueries = JSON.stringify(validObjects);
            updatedQueries && localStorage.setItem(id, validObjects);

            return updatedQueries;
        }
        return serializedQueries ? JSON.stringify(serializedQueries) : null;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
    }
};

export const loadLocalStorageId = (id: *) => {
    // Escape SSR without error
    if (typeof window === "undefined") {
        return null;
    }

    try {
        const data = window.localStorage.getItem(id);
        return JSON.parse(data);
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
    }
};
