export * as contentfulTypenames from "./ContentfulTypenames";
export const defaultLocale = "en";
export const searchPages = [
    {
        slug: "search",
        node_locale: "nl",
    },
    {
        slug: "search",
        node_locale: "fr",
    },
    {
        slug: "search",
        node_locale: "en",
    },
];
