import React from "react";
const defaultContext = {
    videoData: {
        id: null,
        startTime: 0,
        fullScreen: false,
        disablePip: false,
        isPaused: false,
        autoplay: false,
    },
    setVideoData: () => {},
};
const PipContext = React.createContext(defaultContext);
const PipProvider = PipContext.Provider;
export { PipContext, PipProvider };
